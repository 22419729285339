<template>
    <video id="element" class="video-js vjs-big-play-centered vjs-16-9 vjs-playback-rate"></video>
</template>

<script setup>
    import videojs from 'video.js';
    import 'video.js/dist/video-js.css';
    import { ref, onMounted, onUnmounted, watch } from 'vue';
    const player = ref(null);
    const props = defineProps({
        url: String
    });

    const options = {
        controls: true,
        poster: false,
        playbackRates: [0.5, 1, 1.5, 2],
        userActions: {
        hotkeys: function(event) {
            if (event.which === 32) {
                if(this.paused()) {
                    this.play();
                }else {
                    this.pause();
                }
            }
        }
    }
    }
    // watch(props.url, () => {
    //     console.log(props.url, player.value.src);
    //     // player.value.src(props.url);
    //     if(player.value) {
    //         player.value.src(props.url);
    //         // player.value.sources.push({
    //         //     src: props.url,
    //         //     type: 'video/mp4'
    //         // })
    //     }
    // });
    onMounted(() => {
        player.value = videojs('element', options);
    });
    onUnmounted(() => {
        if (player.value) {
            player.value.dispose();
        }
    });
    defineExpose({
        player
    })
</script>

<style>
    .video-js .vjs-big-play-button {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
    .vjs-poster {
        background-size: cover;
        background-repeat: no-repeat;
    }
</style>